:root {
  --orange: #E9521D;
  --anthrazit: #2D2A29;
  --braun: #9B6E49;
  --beige: #E6CFBE;
  --weiss: #FFFFFF;
  --schwarz: #000000;
}

html {
  font-family: 'Montserrat-Regular', Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

.h1,
h1 {
  font-family: 'Montserrat-Bold', Helvetica, Arial, sans-serif;
  font-size: 2rem;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  line-height: 130%;
}



@media only screen and (max-width: 992px) {

  .h1,
  h1 {
    font-size: 1.5rem;
  }

  .main-page-heading-subtitle {
    font-size: 2.5rem !important;
  }

  .career-page-heading-subtitle {
    font-size: 2.5rem !important;
  }

  h2 {
    font-size: 1rem !important;
  }
}

h2 {
  font-family: 'Montserrat-SemiBold', Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  line-height: 140%;
}

h3 {
  font-family: 'Montserrat-SemiBold', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  line-height: 140%;
}

.p,
p {
  font-family: 'Montserrat-Regular', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  line-height: 150%;
}

.small-text p {
  font-family: 'Montserrat-Regular', Helvetica, Arial, sans-serif;
  font-size: 0.75rem !important;
  /*  0.875 in Figma*/
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  line-height: 130%;
}

.textListing {
  /*font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;*/
  font-weight: bold;
  line-height: 150%;
  padding-left: 3px;
  padding-right: 3px;

}

a {
  color: #FFFFFF;
  text-decoration: none;
  font-family: 'Montserrat-Regular', Helvetica, Arial, sans-serif;
}

a:hover {
  color: var(--orange);
  text-decoration: none;
}

.App {
  text-align: center;
}



@media only screen and (min-width: 992px) {
  .text-services-pages {
    padding-left: 3px;
    padding-right: 3px;
  }
}

.main-page-heading-subtitle {
  margin-bottom: 0px;
  color: var(--orange);
  z-index: 2;
  font-size: 4rem;
  hyphens: auto;
  line-height: 120%;
  text-align: center;
}

.career-page-heading-subtitle {
  margin-top: -350px;
  margin-bottom: 50px !important;
  color: var(--orange);
  z-index: 2;
  font-size: 4rem;
  hyphens: auto;
  line-height: 120%;
}

.page-heading-subtitle {
  margin-top: -260px;
  margin-bottom: 50px !important;
  color: var(--orange);
  z-index: 2;
}

.image-gruppe-top {
  margin-bottom: 20px;
}


@media only screen and (max-width: 1200px) {
  .page-heading-subtitle {
    margin-top: -220px;
  }

  .career-page-heading-subtitle {
    margin-top: -400px;
  }

}

@media only screen and (max-width: 992px) {
  .page-heading-subtitle {
    margin-top: 0;
  }




  .page-heading-subtitle {
    margin-top: 20px;

  }

  .career-page-heading-subtitle {
    margin-top: 0px;

  }

  .home-image-carousel {
    display: none;
  }
}

.FurtherServiceHeading {
  margin-top: 150px;
  margin-bottom: 70px;
  text-align: center;
}

.button-orange {
  margin: 0 auto;
  display: block;
  color: var(--weiss);
  background: var(--orange);
  border-color: var(--orange);
  border-radius: 30px;
  padding: 7px, 16px, 7px, 16px;
  border-style: solid;
  padding-inline: 16px;
  padding-block: 6px;
  border-width: 2px;
}

.button-orange:hover {
  background: var(--anthrazit);
  color: var(--orange);
  border-color: var(--orange);
}

.button-orange:disabled,
button-orange[disabled] {
  background-color: #cccccc;
  color: #666666;
  border-color: #cccccc;
}

.button-orange-whiteBG {
  margin: 0 auto;
  display: block;
  color: var(--weiss);
  background: var(--orange);
  border-color: var(--orange);
  border-radius: 30px;
  padding: 8px, 16px, 8px, 16px;
  border-style: solid;
  padding-inline: 16px;
  padding-block: 6px;
  border-width: 2px;
}

.button-orange-whiteBG:hover {
  background: var(--weiss);
  color: var(--orange);
  border-color: var(--orange);
}

.language-button {
  background: transparent;
  border-color: transparent;
  border-radius: 10px;
  padding: 0px;
  padding-right: 10px;
  border: 0px;
}




/* ------------------------------------------------------ HEADER */
.header {
  color: var(--weiss);
}

.header-logo {
  width: 300px;
  margin-bottom: -24px;
}

.navbar-custom {
  background-color: var(--anthrazit) !important;
  margin-bottom: 20px;
}

.navbar-item-custom {
  color: var(--schwarz) !important;
}

.navbar-link-custom {
  color: var(--weiss) !important;
}

.nav-link {
  color: var(--weiss) !important;
  margin-top: 10px;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0px;
}

.nav-item.dropdown .dropdown-menu {
  margin-top: 0px;
}

.nav-item.dropdown:hover .dropdown-toggle {
  color: var(--orange) !important;
}

.navlink-menubar:hover {
  color: var(--orange) !important;
}

.navbar-mandanten {
  margin-right: 20px;
  margin-bottom: 10px;
  position: absolute;
  z-index: 2;
  right: -2px;
}

.dropdown-item {
  padding-top: 8px;
  padding-bottom: 8px;
}

.navlink-mandanten:hover {
  color: var(--weiss) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--orange);
  opacity: 0.7;
}


@media only screen and (min-width: 992px) {
  .navlink-mandanten-dropdown {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .navbar-mandanten {
    display: none;
  }

  .navbar-mandanten-bg-img {
    display: none;
  }

  .navbar-nav {
    margin-top: 10px;
  }

  .nav-link {
    margin-left: 20px;
  }

  .dropdown-menu {
    margin-top: 0px !important;
  }

  .dropdown-item {
    padding-left: 40px !important;
  }

  .navbar-toggler {
    border: none;
    border-style: none;
    margin-top: 20px !important;
    margin-right: 8px !important;
  }

  .navbar-toggler:focus {
    box-shadow: none;
    outline: none;
  }

  .navbar-brand {
    margin: 0px;
  }


  .navbar-toggler-icon {
    color: var(--orange) !important;
    border: none;
    /* background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(233,82,29, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important; */
    background-image: url(/public/icons/burger_menu_open.svg) !important;
  }

  .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background-image: url(/public/icons/burger_menu_close2.svg) !important;

  }

  /* .navbar-toggler-icon {
    background-image: url(/public/icons/burger_menu_open.svg) !important;
  } */
  .nav-item.dropdown {
    display: contents;
  }


}

@media only screen and (max-width: 370px) {
  .header-logo {
    display: none;
  }

  .header-logo-signet {
    margin-bottom: -39px;
    margin: 10px;
    padding: 5px;
    margin-left: 15px;
    margin-bottom: -16px;
  }
}

@media only screen and (min-width: 370px) {
  .header-logo-signet {
    display: none;
  }
}

.navlink-mandanten {
  color: var(--anthrazit) !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown {
  color: var(--weiss);
  font-family: 'Montserrat-SemiBold', Helvetica, Arial, sans-serif;
}


.dropdown-menu {
  background-color: var(--anthrazit);
  box-shadow: none;
}

.navbar-nav .dropdown-menu {
  padding-top: 22px;
  border-radius: 0px;
  border: none;
  margin-left: -5px;
}

@media only screen and (max-width: 990px) {
  .navbar-nav .dropdown-menu {
    padding-top: 0px;
    border-radius: 0px;
    border: none;
    margin-left: 0px;
  }
}


.dropdown-item {
  color: var(--weiss);
}

.dropdown-item:hover {
  background-color: var(--orange);
  color: var(--weiss);
}

/*
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0px; /* remove the gap so it doesn't close */

/* ------------------------------------------------------ END HEADER */

/* ------------------------------------------------------ MEGA MENU*/
.mega-menu {
  width: 100%;
  padding: 20px;
  border: none;
  /* entfernt den Rahmen */
}

.mega-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mega-menu ul li a {
  display: block;
  padding: 5px 0;
  color: #fff;
  /* setzt die Schriftfarbe auf weiß */
  text-decoration: none;
}

/* ------------------------------------------------------ END MEGA MENU*/


/* ------------------------------------------------------ FOOTER */
.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  background: var(--anthrazit);
  color: var(--weiss);
}

@media only screen and (max-width: 991px) {
  .footer {
    text-align: center;
  }
}

.footer-firstRow {
  padding-top: 40px;
  padding-bottom: 10px;
}

.footer-firstRow h2 {
  margin-bottom: 20px;
}

.footer-link {
  color: var(--weiss);
  text-decoration: none;
}

.footer-social-certificate {
  background-color: var(--beige);
  width: 100%;
  height: 100px;
}

@media only screen and (max-width: 991px) {
  .footer-openingHours {
    margin-bottom: 24px;
  }

  .footer-followUs {
    margin-bottom: 40px;
  }

  .footer-certificates {
    margin-bottom: 40px;
  }

  .footer-contact {
    margin-bottom: 40px;
  }

  .footer-certificates {
    display: none;
  }

}

/* ------------------------------------------------------ END FOOTER */


/* ------------------------------------------------------ CAROUSEL */

.carousel-container {
  height: 400px;
  margin-left: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 900px) {
  .carousel-container {
    width: 100% !important;
    overflow: visible;
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 992px) {
  .carousel-container {
    margin-left: 0px;
  }

  .carousel-container-home {
    width: 100% !important;
    max-width: 100%;

  }
}

@media only screen and (max-width: 575px) {
  .carousel-container {
    margin-left: 0px;
  }
}

.left-section-carousel {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: flex-start;
  height: 400px;
}

.col-left-section-carousel {
  padding-right: 0;
  z-index: 2;
  margin-right: -80px;
  background: linear-gradient(to right, transparent, 0%, #2D2A29, 97.5%, transparent);
}

@media only screen and (max-width: 1399px) {
  .carousel__viewport {
    padding-left: 120px;
  }
}

@media only screen and (max-width: 1199px) {
  .carousel__viewport {
    padding-left: 250px;
  }
}

@media only screen and (max-width: 991px) {
  .carousel__viewport {
    padding-left: 140px;
  }
}

@media only screen and (max-width: 900px) {
  .carousel__viewport {
    padding-left: 0px;
  }
}



@media only screen and (max-width: 900px) {
  .col-left-section-carousel {
    padding-right: 0;
    z-index: 2;
    background: #2D2A29;
    margin-right: 0px;
    width: 100%;
    height: fit-content;
    padding-bottom: 5px;
  }
}



.right-section-carousel {
  padding-top: 20px;
  z-index: 0;
  padding-bottom: 20px;
  position: relative;
  margin-left: -10px;
  background-color: #2D2A29;
  /* width: 1200px; */
  margin-right: -80px;
}

@media only screen and (max-width: 991px) {
  .right-section-carousel {
    z-index: 0;
    padding-bottom: 20px;
    position: relative;
    margin-left: 0px;
    background-color: #2D2A29;
    /* width: 1200px; */
    margin-right: -252px;
  }
}

@media only screen and (max-width: 900px) {
  .right-section-carousel {
    margin-top: -5px;
    padding-top: 0px;

  }

  .col-right-section-carousel {
    width: 100%;
    z-index: 5 !important;
  }

  .left-section-carousel {
    height: fit-content !important;
    z-index: 1 !important;
  }
}

.carousel-heading {
  color: var(--orange);
  font-size: xx-large;
  text-align: left;
  padding: 60px;
  padding-left: 20px;
  padding-bottom: 10px;
  line-height: 30px;
}

.carousel-text {
  color: var(--weiss);
  text-align: left;
  /* padding: 40px; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

@media only screen and (max-width: 1199px) {
  .carousel-heading {
    padding-top: 40px;
    padding-right: 20px;
  }

  .carousel-text {
    margin-bottom: 0;
  }
}

.col-right-section-carousel {
  padding-left: 0;
  z-index: 0;

}

.carousel-item {
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 270px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  border-color: var(--weiss);
  border-width: 1, 2px;
  border-style: solid;
  color: black;
}

.carousel-item:hover {

  background-color: var(--anthrazit);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  border-color: var(--orange);
  border-width: 1, 2px;
  border-style: solid;
  color: var(--weiss);
}

.services-carousel-item {
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 270px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  float: inline-start;
  margin: 15px;
  color: black;
  border-color: var(--weiss);
  border-width: 1, 2px;
  border-style: solid;
  color: black;
}

.services-carousel-item:hover {
  background-color: var(--anthrazit);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  border-color: var(--orange);
  border-width: 1, 2px;
  border-style: solid;
  color: var(--weiss);
}

.ServiceTilesRow {
  justify-content: center;
  margin-bottom: 150px;
}

@media only screen and (min-width: 1200px) {
  .ServiceTileContainer {
    padding-left: 130px;
    padding-right: 130px;
  }
}

@media only screen and (min-width: 1400px) {
  .ServiceTileContainer {
    padding-left: 220px;
    padding-right: 220px;
  }
}

.carousel-item-icon {
  align-self: self-start;
  padding: 10px;
  font-size: xx-large;

}

.carousel-item-heading {
  text-align: start;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  font-size: 20px;
  color: var(--orange);
  height: 50px;
  justify-content: end;
}

.carousel-item-text {
  text-align: start;
  padding: 10px;
  padding-bottom: 0px;
  font-size: 12px;
}

.carousel-item-arrow {
  position: absolute;
  padding: 5px;
  padding-right: 10px;
  font-size: xx-large;
  align-self: self-end !important;
  bottom: 1px;
  right: 5px;
}

.services-carousel-item-arrow {
  position: absolute;
  padding: 5px;
  padding-right: 10px;
  font-size: xx-large;
  align-self: self-end !important;
  bottom: 15px;
  right: 5px;
}

@media only screen and (max-width: 1199px) {
  .services-carousel-item-arrow {
    right: 30px;
  }
}


.carousel {
  position: relative;
  height: 360px;
}

.carousel__viewport {
  overflow: hidden;
  align-items: center;
  height: 360px;
}

.carousel__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.carousel__viewport.is-dragging {
  cursor: grabbing;
}

.embla__viewport.is-wheel-dragging {
  cursor: move;
  cursor: grab;
}

.carousel__container {
  display: flex;
  will-change: transform;
  margin-left: -30rem;
  align-items: center;
  height: 360px;
}

@media only screen and (max-width: 900px) {
  .carousel__container {
    margin-left: -88vw;
  }
}

.carousel__slide {
  flex: 0 0 auto;
  /* width: 80%; */
  position: relative;
  padding-left: 30px;
  counter-increment: embla;
}

.carousel__slide__inner {
  background-color: rgb(40, 44, 52);
  position: relative;
  border-radius: 0.5rem;
  min-height: 200px;
  /* padding-bottom: 46%; */
  /* font-size: 5rem; */
}

.carousel[data-axis="y"] .carousel__slide__inner {
  padding-bottom: 0;
  height: 100%;
}

.carousel__slide__inner:before {
  color: white;
  font-weight: 300;
  line-height: 1;
  content: counter(carousel);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel__button {
  background-color: transparent;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.carousel__button:not(:disabled) {
  cursor: pointer;
  fill: #1bcacd;
}

.carousel__button:disabled {
  fill: #e9e9e9;
  display: none;
}

.carousel__button__svg {
  width: 3.5rem;
  height: 3.5rem;
}

.carousel__button--prev {
  left: 3.7rem;
  z-index: 5;
}

.carousel__button--next {
  right: 0.7rem;
}

@media only screen and (max-width: 990px) {

  .carousel__button--next {
    right: 10rem;
  }
}

@media only screen and (max-width: 900px) {
  .carousel__button--prev {
    display: none;
  }

  .carousel__button--next {
    display: none;
  }
}

.carousel__dots {
  position: absolute;
  bottom: 10px;
  display: flex;
  margin-left: 20px;
  list-style: none;
  justify-content: center;
  z-index: 99 !important;
}

.carousel__dots__right {
  position: absolute;
  bottom: 1px;

  display: flex;
  margin-left: 32px;
  list-style: none;
  justify-content: center;
  z-index: 99 !important;
}



@media only screen and (max-width: 900px) {
  .carousel__dots {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .carousel__dots__right {
    display: none;
  }
}

.carousel__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  height: 4rem;
  width: 1.2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border: 0;
  display: flex;
  align-items: center;
}

.carousel__dot:after {
  background-color: #efefef;
  width: 100%;
  /* height: 0.5rem; */
  height: 4px;
  content: "";
  /* width: 1.5rem; */
  width: 15px;
}

.carousel__dot.is-selected:after {
  background-color: var(--orange);
  opacity: 1;
  transform: scaleX(2);
}

/* ------------------------------------------------------ END CAROUSEL */

/* ------------------------------------------------------ CONTACT */
.contact-wraper {
  background-color: var(--anthrazit);
  padding-top: 100px;
  padding-bottom: 100px;
}

.contact-img {
  float: right;
  max-width: 357px;
}

.contact-content {
  text-align: center;
  color: var(--weiss);
}

.box {
  float: left;
  text-align: center;
}

/* ------------------------------------------------------ END CONTACT*/

/* ------------------------------------------------------ CAREER */
.career-form-wrapper {
  padding-top: 150px;
  padding-bottom: 150px;
  background: var(--anthrazit);
}

.career-form h1 {
  color: var(--weiss);
}

.career-form h2 {
  margin-top: 30px;
  color: var(--weiss);
}

.contact-img {
  float: right;
  max-width: 357px;
}

.career-rectangle {
  /* Rectangle 49 */

  width: 370px;
  height: 100px;
  margin-top: 30px;
  margin-right: 30px;

  /* Bailänder Weiß */

  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.career-CallToAction {
  margin-top: 150px;
  margin-bottom: 150px;
  padding-top: 100px;
  padding-bottom: 100px;
  background: var(--anthrazit);
  height: fit-content;
}

@media only screen and (max-width: 575px) {
  .career-CallToAction-Container {
    padding: 0px;
  }
}

.career-button {
  margin: 0 auto;
  display: block;
  color: var(--weiss);
  background: var(--orange);
  border-color: var(--orange);
  border-radius: 30px;
  padding: 8px, 16px, 8px, 16px;
  border-style: solid;
  padding-inline: 16px;
  padding-block: 6px;
  border-width: 2px;
}

.career-button:hover {
  background: var(--anthrazit);
  color: var(--orange);
  border-color: var(--orange);
}

.job-field {
  margin: 24px 0px 0px 0px;
  background-color: var(--weiss);
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--weiss);
}

.job-field:hover {

  background-color: var(--anthrazit);
  border-radius: 20px;
  border-color: var(--orange);
  border-width: 1px;
  border-style: solid;
  color: var(--weiss);
  cursor: pointer;
}

.h3-job-orange {
  color: var(--orange);
}

.p-job-orange {
  color: var(--orange);
}

@media only screen and (max-width: 767px) {
  .h3-job-orange {
    color: var(--orange);
    margin-top: 20px;
  }

  .p-job-orange {
    color: var(--orange);
  }

  .job-field {
    line-height: 0px !important;
  }
}
/* ------------------------------------------------------ END CAREER*/

/* ------------------------------------------------------ CONTACT FORM*/
.testFild {
  background-color: #61dafb;
  min-height: 30px;
}

.contact-form-wraper {
  padding-top: 150px;
  padding-bottom: 150px;
  background: var(--anthrazit);
}

.contact-form-title-col {
  margin-bottom: 20px;
}

.contact-form-infos-col {
  margin-bottom: 20px;
}

/* .contact-form-wraper button {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  background-color: #E9521D;
}

.contact-form-wraper button:hover {
  background-color: #E9521D;
  color: var(--anthrazit);
} */

.in-line {
  float: left;
}

.form-icons {
  vertical-align: middle;
  margin-bottom: 20px;
}

.form-icons img {
  margin-right: 20px;
}

.contact-form {
  color: #FFFFFF;

}




.h1text {
  font-size: 32px;
  font-family: 'Montserrat-Regular';
  font-weight: 700;
}

.h2text {
  margin-bottom: 25px;
  font-size: 20px;
  font-family: 'Montserrat-Regular';
  font-weight: 600;
}

.form-control {
  font-family: 'Montserrat-Regular';
  background-color: var(--anthrazit);
  color: #FFFFFF;
  border-color: #E9521D !important;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: var(--orange) !important;
}

.form-control:focus {
  background-color: var(--anthrazit);
  color: #FFFFFF;
  outline: var(--orange) !important;
}

input:not(:placeholder-shown) {
  border-color: green !important;
}

input {
  color: #FFFFFF;
}

/* ------------------------------------------------------ END CONTACT FORM*/
/* ------------------------------------------------------ BERATER*/
.berater-wraper {
  margin-top: 100px;
  margin-bottom: 250px;
}

@media only screen and (max-width: 1200px) {
  .berater-wraper {
    margin-bottom: 100px;
  }
}

.berater-wraper h2 {
  margin-top: 45%;
}

.berater-wraper img {
  width: 100%;
}

.berater-wraper a {
  margin-top: 50px;
  text-align: center;
}

.col-mitarbeiter {
  padding-top: 70px;
}

@media only screen and (max-width: 767px) {
  .berater-wraper img {
    width: 65%;
  }

  .berater-wraper h2 {
    margin-top: 10%;
  }

  .berater-wraper-upper-col1 {
    text-align: center !important;
  }

  .berater-wraper-upper-col2 {
    text-align: center !important;
    padding-bottom: 50px;
  }

  .berater-wraper-upper-col2 h2 {
    margin-top: 5% !important;
  }

  .berater-wraper-bottom-col1 {
    text-align: center !important;
    order: 3;
  }

  .berater-wraper-bottom-col1 h2 {
    margin-top: 5% !important;
  }

  .berater-wraper-bottom-col2 {
    text-align: center !important;

  }

  .berater-wraper-bottom-cols-image {
    float: none !important;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 400px) {
  .berater-wraper img {
    width: 75%;
  }

  .berater-wraper h2 {
    margin-top: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .image_berater_color {
    display: none !important;
  }
}

@media only screen and (min-width: 767px) {
  .image_berater_sw {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .image_berater_color {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .3s;
  }

  .image_berater_color:hover {
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .team-page-berater-bottom {
    padding-top: 110px;
  }
}



@media only screen and (max-width: 767px) {
  .image_mitarbeiter_color {
    display: none !important;
  }
}

@media only screen and (min-width: 767px) {

  .image_mitarbeiter_color {
    padding-top: 70px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .3s;
  }

  .image_mitarbeiter_color:hover {
    padding-top: 70px;
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}


/* ------------------------------------------------------ END BERATER*/
/* ------------------------------------------------------ DIAMOND TEXT */

.diamondText {
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: left;
  height: 250px;
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .diamondText {
    margin-bottom: -40px;
  }
}

.diamondText-picture {
  position: relative;
}

.diamondText-title {
  position: absolute;
  bottom: 145px;
  left: 30px;
  color: #000000;

}


.diamondText-description {
  position: absolute;
  top: 100px;
  left: 30px;
  position: absolute;
  line-height: 17px;
  color: #000000;
  font-size: 0.75rem
    /*  END DIAMOND TEXT */
}

/* ------------------------------------------------------ END DIAMOND TEXT */

/* ------------------------------------------------------ MODAL POPUP */
.modal-dialog {
  color: var(--weiss);
}

.modal-header {
  border-bottom: 0;
}

.modal-content {
  background: var(--anthrazit);
  border-radius: 146px;
  padding: 50px;

}

.modal-custom {
  width: 100%;
  padding-left: 0px;
}


.modal-custom-body {
  padding: 50px;
  padding-top: 50px;
}

@media only screen and (max-width: 550px) {

  .modal-custom-body {
    padding: 0px;
  }

  .modal-content {
    padding: 10px;

  }
}

.modal-custom-body h2 {
  color: var(--orange);
}

.modal-custom-p-hours {
  color: var(--orange);
}

/* .modal-custom-footer {} */

/* ------------------------------------------------------ END MODAL POPUP */

/* ------------------------------------------------------ IMAGE CAROUSEL */


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: auto;
  height: auto;
  object-fit: cover;
}

.swiper-slide {
  width: auto;
}

.swiper-slide:nth-child(2n) {
  width: auto;
}

.swiper-slide:nth-child(3n) {
  width: auto;
}

.swiper-slide:nth-child(4n) {
  width: auto;
}

.swiper-slide:nth-child(n) {
  width: auto;
}


/* ------------------------------------------------------ END IMAGE CAROUSEL */
/* ------------------------------------------------------ CONTACT PAGE */
.contact-page-wraper {
  background: var(--anthrazit);
  color: var(--weiss);
}

.contact-page-wraper h1 {
  margin-top: 150px;
}

.contact-page-wraper h2 {
  margin-bottom: 20px;
}

/* ------------------------------------------------------ END CONTACT PAGE */


/* ------------------------------------------------------ APPLICATION PAGE */

.application_field_title {
  margin-bottom: 30px;
}

.application_form_select {
  font-family: 'Montserrat-Regular';
  background-color: var(--anthrazit);
  color: #FFFFFF;
  border-color: var(--orange) !important;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: var(--orange) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23E9521D%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e")
}

.form-select option:focus {
  background-color: var(--orange) !important;
  color: #fff;
}

.application_datepicker{
  font-family: 'Montserrat-Regular';
  background-color: var(--anthrazit);
  color: #FFFFFF;
  border-color: var(--orange) !important;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: var(--orange) !important;
}

input:not(:placeholder-shown) {
  font-family: 'Montserrat-Regular';
  background-color: var(--anthrazit);
  color: #FFFFFF;
  border: 1px solid;
  border-color: var(--orange) !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0.375rem;
  background-clip: padding-box;
  box-shadow: var(--orange) !important;
}

.react-datepicker__month-container {
  font-family: 'Montserrat-Regular';
  color: var(--weiss) !important;
  background-color: var(--anthrazit);

}

.react-datepicker__header {
  color: var(--weiss) !important;
  background-color: var(--anthrazit) !important;
}

.react-datepicker__current-month {
  font-weight: 500 !important;
  color: var(--weiss) !important;
  background-color: var(--anthrazit) !important;
}

.react-datepicker__day{
  color: var(--weiss) !important;
}

.react-datepicker__day-name{
  color: var(--weiss) !important;
}

.react-datepicker__navigation-icon--previous {
  top: 5px;
}


/* ------------------------------------------------------ END APPLICATION PAGE */

.dia-test {
  margin-top: 200px;
  margin-bottom: 200px;


}

.dia {
  padding-top: 80px;
  padding-left: 60px;
  min-height: 200px;
  background-image: url(../public/icons/diamond-orange.png);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 200px;
}

.employe-section {
  margin-bottom: 150px;
}

.employe-section img {
  display: block;
  margin: 0 auto auto;
  max-width: 100%
}

.employe-section col {
  margin-bottom: 50px;
}

.page-hero {
  margin-top: 100px;
}

@media only screen and (max-width: 576px) {
  .page-hero {
    margin-top: 50px;
  }
}

.service-page-content {
  margin-top: 100px;
  margin-bottom: 100px;
}

.service-page-content h1 {
  margin-bottom: 50px;
}

.diamond-section {
  margin-bottom: 150px;
}

.diamond-section h1 {
  margin-bottom: 50px;
}

.not-visible {
  visibility: hidden;
}

.is-visible {
  margin-top: 20px;
  text-align: center;
  color: green;
  visibility: visible;
}
/*This file is only for fonts*/

@font-face {
    font-family: 'Montserrat-Regular';
    src: local('Montserrat-Regular'),
         url('/public/fonts/Montserrat-Regular.woff') format('woff'),
         url('/public/fonts/Montserrat-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: local('Montserrat-SemiBold'),
         url('/public/fonts/Montserrat-SemiBold.woff') format('woff'),
         url('/public/fonts/Montserrat-SemiBold.woff2') format('woff2');
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: local('Montserrat-Bold'),
         url('/public/fonts/Montserrat-Bold.woff') format('woff'),
         url('/public/fonts/Montserrat-Bold.woff2') format('woff2');
}